import DeleteIcon from '@material-ui/icons/Delete';
import CommentIcon from '@material-ui/icons/Comment';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';

export const CustomDeleteIcon = () => {
    return (
        <IconButton size='small' style={{ outline: 0,color:"white" }} >
            <DeleteIcon />
        </IconButton>
    );
};

export const CustomHighlightIcon = ({color='white'}) => {
    
    return (
        <IconButton size='small' style={{ outline: 0,color:color }} >
               <EditIcon />
        </IconButton>
    );
};

export const CustomCommentIcon = () => {
    return (
        <IconButton size='small' style={{ outline: 0,color:'white' }} >
            <CommentIcon />
        </IconButton>
    );
};

export const ColorInput = ({ color, setColor }) => {
    return (
        <>
            <input
                type="color"
                value={color}
                list="presetColors"
                className='icon-button'
                style={{ width: '50px', outline: 0 }}
                onChange={(event) => { setColor(event.target.value) }}
            />
            <datalist id="presetColors">
                <option>#f56a73</option>
                <option>#f69929</option>
                <option>#fefe54</option>
                <option>#9dd676</option>
                <option>#9bffff</option>
                <option>#b7a3ff</option>
                <option>#fb96ff</option>
            </datalist>
        </>
    )
}
