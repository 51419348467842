import { FETCH_JUDGEMENT_FAILURE, FETCH_JUDGEMENT_SUCCESS } from "./FetchJudgementListTypes"

import {
    SET_JUDGEMENT_DATA,
    SET_JUDGEMENT_LOAD,
    SET_JUDGEMENT_OFFSET,
    SET_JUDGEMENT_FROM,
    SET_JUDGEMENT_TO,
    SET_JUDGEMENT_YEAR,
    SET_JUDGEMENT_LANGUAGE
} from "./FetchJudgementListTypes"


let date = (new Date().toISOString() + "").substring(0, 10) //new String(new Date().toISOString()).substring(0,10);

const initialState = {
    data: [],
    error: '',
    offset: 0,
    loadMore: true,
    from: date,
    to: date,
    language: 1,
    year: 2016,
}

const fetchJudgementListReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_JUDGEMENT_FAILURE:
            return {
                ...state,
                data: [],
                error: action.error
            }

        case FETCH_JUDGEMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: '',
                offset: action.offset,
                loadMore: action.loadMore,
            }

        case SET_JUDGEMENT_DATA:
            return {
                ...state,
                data: action.payload,
            }
        case SET_JUDGEMENT_LOAD:
            return {
                ...state,
                loadMore: action.payload,
                // error : action.error
            }
        case SET_JUDGEMENT_OFFSET:
            return {
                ...state,
                offset: action.payload,
                // error : action.error
            }
        case SET_JUDGEMENT_LANGUAGE:
            return {
                ...state,
                language: action.payload,
                // error : action.error
            }
        case SET_JUDGEMENT_YEAR:
            return {
                ...state,
                year: action.payload,
                // error : action.error
            }
        case SET_JUDGEMENT_FROM:
            return {
                ...state,
                from: action.payload,
                // error : action.error
            }
        case SET_JUDGEMENT_TO:
            return {
                ...state,
                to: action.payload,
                // error : action.error
            }


        default:
            return state;

    }
}

export default fetchJudgementListReducer