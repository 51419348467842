import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'


export const fetchChaptersUnderPart = (lawId, partId) => {
    return new Promise((resolve, reject)=>{
        
        axios.get(`${HOST}chapter/?law=${lawId}&part=${partId}`,
        // axios.get(`${HOST}subheading/?law=${""}&part=${partId}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}
