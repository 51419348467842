import React, { useEffect } from "react";
import MainContentSection from "../../components/MainContent/MainContentSection";
import { makeStyles } from "@material-ui/core/styles";
import { setMenuItems } from "../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions";
import { connect } from "react-redux";
import useState from "react-usestateref";
import { fetchSpecificDebate } from "../../Redux/APIs/FetchDebatesList/FetchDebateListActions";
import { CircularProgress } from "@material-ui/core";
import parse from "html-react-parser";
import { getLoginStatus, getUserId } from "../../config/cookiesInfo";
import {
  createLastContentReadLocation,
  recallTheContentPreviouslyRead,
  updateLastContentReadLocation,
} from "../../Redux/APIs/CommonNetworkCalls";
import { scrollToPosition } from "../../utilities/Utils";
import TextHighlighter from "../../components/textHighlighter/TextHighlighter";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    paddingTop: 30,
    fontWeight: 600,
    paddingLeft: 20,
    paddingRight: 20,
    // [theme.breakpoints.down('xs')]:{
    //     paddingTop:20,
    //     fontSize:25
    // }
    [theme.breakpoints.up("xs")]: {
      paddingTop: 15,
      fontSize: 19,
      marginBottom: -7,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 25,
      fontSize: 22,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 31,
      fontSize: 23,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 35,
      fontSize: 26,
    },
  },
  divider: {
    width: "90%",
    align: "center",
    backgroundColor: "black",
  },
  content: {
    width: "80%",
    margin: "auto",
    // fontSize:17,
    paddingTop: 1,
    [theme.breakpoints.up("xs")]: {
      marginTop: 0,
      fontSize: 15,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      fontSize: 16,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 10,
      fontSize: 17,
    },
  },
  container: {
    align: "center",
  },
  textContent: {
    marginTop: 20,

    [theme.breakpoints.up("xs")]: {
      fontSize: 15,
      // marginBottom:3,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      // marginBottom:5,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
      // marginBottom:6,
    },
  },
  spinner: {
    margin: "auto",
    marginTop: "5%",
    // padding:'auto',
    color: "blue",
    // display:'flex',
    // justifyContent: 'center'
  },
  spinnerContainer: {
    display: "flex",
  },
}));

function IndividualDebate(props) {
  const classes = useStyles();

  const [title, setTitle, refTitle] = useState("");
  const [content, setContent, refContent] = useState("");
  const [spinner, setSpinner, refSpinner] = useState(true);
  const [present, setPresent, refPresent] = useState(true);
  const [hasLastReadData, setLastReadData, refHasLastReadData] =
    useState(false);
  const [lastReadLocatoin, setLastReadLocation, refLastReadLocation] = useState(
    {
      user: getUserId(),
      module_id: props.match.params.id,
      module_type: "debate",
      complete_scrolling_percent: null,
      channel: "web",
      page_link: "",
      index: null,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setMenuItems([], 2);
  }, [props]);

  useEffect(() => {
    fetchSpecificDebate(props.match.params.id)
      .then((res) => {
        if (res.data.title) {
          setContent(res.data.content);
          setTitle(res.data.title);
        
        //   if(getLoginStatus()){
        //   recallTheContentPreviouslyRead(props.match.params.id, "debate")
        //     .then((res) => {
        //       if (res.data.length !== 0) {
        //         setLastReadData(true);
        //         document.getElementById("container").scrollTo({
        //           top: 5,
        //           behavior: "smooth", // Optionally, you can use 'auto' or 'smooth' for smooth scrolling
        //         });
        //         setTimeout(() => {
        //           const element = document.getElementById("container");

        //           scrollToPosition(
        //             element.scrollHeight,
        //             element.clientHeight,
        //             res.data[0].complete_scrolling_percent
        //           );
        //         }, 200);
        //         setLastReadLocation({
        //           ...lastReadLocatoin,
        //           complete_scrolling_percent:
        //             res.data[0].complete_scrolling_percent,
        //           id: res.data[0].id,
        //         });
        //       } else {
        //         setLastReadData(false);
        //       }
        //     })
        //     .catch((err) => {});
        // }
        } else {
          setPresent(false);
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        setPresent(false);
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   const delay = 2000; // Delay in milliseconds (2 seconds in this example)

  //   const timerId = setTimeout(() => {
  //     if (hasLastReadData) {
  //       console.log(lastReadLocatoin.current);
  //       updateLastContentReadLocation(
  //         refLastReadLocation.current,
  //         refLastReadLocation.current.id
  //       ).then((res) => {
  //         console.log("UPDATE CONTENT REMEMBER", res);
  //       });
  //     } else {
  //       if (
  //         !hasLastReadData &&
  //         refLastReadLocation.current.complete_scrolling_percent
  //       ) {
  //         createLastContentReadLocation(refLastReadLocation.current).then(
  //           (res) => {
  //             console.log("CREATE CONTENT REMEMBER", res);
  //             setLastReadData(true);
  //             setLastReadLocation({ ...lastReadLocatoin, id: res.data.id });
  //           }
  //         );
  //       }
  //     }
  //   }, delay);

  //   return () => {
  //     // Cleanup function to clear the timer if the component unmounts
  //     clearTimeout(timerId);
  //   };
  // }, [lastReadLocatoin.complete_scrolling_percent]);

  const onScrollListener = (event) => {
    // if (getLoginStatus()) {
    //   const { scrollTop, scrollHeight, clientHeight } = event.target;
    //   const position = (scrollTop / (scrollHeight - clientHeight)) * 100;
    //   console.log("position", position);
    //   setLastReadLocation({
    //     ...lastReadLocatoin,
    //     complete_scrolling_percent: `${position.toFixed(2)}`,
    //   });
    // }
  };

  return (
    <>
      {refSpinner.current ? (
        <MainContentSection>
          <div className={classes.container}>
            <div className={classes.spinnerContainer}>
              <CircularProgress className={classes.spinner} />
            </div>
          </div>
        </MainContentSection>
      ) : refPresent.current === false ? (
        <MainContentSection>
          <div className={classes.container}>
            <h3 className={classes.heading} style={{ paddingTop: 50 }}>
              No data found
            </h3>
          </div>
        </MainContentSection>
      ) : (
        <MainContentSection onScrollListener={onScrollListener}>
          <div className={classes.container}>
            <h2 className={classes.heading}>{refTitle.current}</h2>
            <hr className={classes.divider} />
            <div className={classes.content}>
              {/* {parse(refContent.current)} */}
              <TextHighlighter 
                module_type = {'debate'}
                text={<HtmlStructure html={refContent.current} />}
                // text={parse(refContent.current)}
                module_id={props.match.params.id}
              />
              {/* {refContent.current.split('\n').map((item,index)=>{
                                return (<p className={classes.textContent} key={index}>
                                    {item}
                                </p>)

                            })} */}
            </div>
          </div>
        </MainContentSection>
      )}
    </>
  );
}

function HtmlStructure({ html }) {
  // Replace <p>, <div>, and <pre> tags with \n and remove other tags
  const formattedText = html
    .replace(/<\/?(p|div|pre)[^>]*>/g, '\n')
    .replace(/<[^>]+>/g, '')
    .trim();

  return formattedText;
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
  };
};

export default connect(null, mapDispatchToProps)(IndividualDebate);
