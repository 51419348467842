import React from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Login from '../../components/Login/Login';
import { getLoginStatus } from '../../config/cookiesInfo';
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions';
import { displayLeftSidebar } from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions';

const UserNoteLink = () => {

    const history = useHistory();

    const moveTo = () => {
        history.push('/user-notes')
    }

    return (
        <>
            {
                getLoginStatus() ?
                    moveTo()
                    : <Login pathToGo={'/user-notes'} />
            }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
        displayLeftSidebar: (val) => dispatch(displayLeftSidebar(val)),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(UserNoteLink)
