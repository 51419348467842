import axios from "axios";
import { HOST } from "../../config/config";
import { getToken } from "../../config/cookiesInfo";


export const recallTheContentPreviouslyRead = (module_id, module_type) => {
    
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}last_read_location/?module_type=${module_type}&module_id=${module_id}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
   
}


export const updateLastContentReadLocation = (data, id) => {
    return new Promise((resolve, reject)=>{
    axios.put(`${HOST}last_read_location/${id}/`, data,
    {
        headers:{
            "Authorization" : `token ${getToken()}`
        }
    }, {})
    .then((res)=>{
        resolve(res);
    }).catch((err)=>{
        reject(err)
    });
})
}

export const createLastContentReadLocation = (data) => {
    return new Promise((resolve, reject)=>{
    axios.post(`${HOST}last_read_location/`, data,
    {
        headers:{
            "Authorization" : `token ${getToken()}`
        }
    })
    .then((res)=>{
        resolve(res)
    }).catch((err)=>{
        reject(err)
    });
});
}
