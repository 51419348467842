import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useState from "react-usestateref";
import { CircularProgress } from "@material-ui/core";
import parse from "html-react-parser";
import FullScreenContent from "../../components/MainContent/FullScreenContent";

import { fetchSpecificSection } from "../../Redux/APIs/FetchDetails/FetchSections";
import KnowledgeGraphButton from "../../components/KnowledgeGraph/KnowledgeGraphButton";
import { fetchNextPageSections } from "../../Redux/APIs/FetchDetails/FetchSectionsUnderChapter";
import { getUserId } from "../../config/cookiesInfo";
import {
  createLastContentReadLocation,
  recallTheContentPreviouslyRead,
  updateLastContentReadLocation,
} from "../../Redux/APIs/CommonNetworkCalls";
import { scrollToPosition } from "../../utilities/Utils";
import TextHighlighter from "../../components/textHighlighter/TextHighlighter";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    paddingTop: 30,
    fontWeight: 600,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    // [theme.breakpoints.down('xs')]:{
    //     paddingTop:20,
    //     fontSize:25
    // }
    [theme.breakpoints.up("xs")]: {
      paddingTop: 20,
      fontSize: 19,
      marginBottom: -7,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 25,
      fontSize: 22,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 31,
      fontSize: 24,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 35,
      fontSize: 25,
    },
  },
  divider: {
    width: "90%",
    align: "center",
    backgroundColor: "black",
  },
  pagination: {
    width: "90%",
    display: "flex",
    position: "fixed",
    top: "50%",
    justifyContent: "space-between",
  },
  previous: {
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: "#white",
    color: "white",
    width: 36,
    height: 36,
    // paddingTop: 5,
    // paddingBottom: 5,
    // paddingRight: 8,
    // paddingLeft: 8,
    // borderRadius: 5,
    // minWidth: 80,
    borderRadius: "50%",
  },
  next: {
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: "white",
    color: "white",
    width: 36,
    height: 36,
    // paddingTop: 5,
    // paddingBottom: 5,
    // paddingRight: 8,
    // paddingLeft: 8,
    // borderRadius: 5,
    // minWidth: 80,
    textAlign: "center",
    borderRadius: "50%",
  },
  arrow: {
    fill: "#0D0D77",
    width: 24,
    height: 24,
  },
  content: {
    width: "80%",
    margin: "auto",
    fontSize: 17,
    paddingTop: 1,
    [theme.breakpoints.up("xs")]: {
      marginTop: -10,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: -7,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },

  },
  container: {
    align: "center",
    position: "relative",
    minHeight: "100%",
  },
  textContent: {
    marginTop: 15,

    [theme.breakpoints.up("xs")]: {
      fontSize: 15,
      // marginBottom:3,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      // marginBottom:5,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
      // marginBottom:6,
    },
  },
  spinner: {
    margin: "auto",
    marginTop: "5%",
    // padding:'auto',
    color: "blue",
    // display:'flex',
    // justifyContent: 'center'
  },
  spinnerContainer: {
    display: "flex",
  },
}));

function SectionInfo(props) {
  const classes = useStyles();

  const [title, setTitle, refTitle] = useState("");
  const [content, setContent, refContent] = useState("");
  const [spinner, setSpinner, refSpinner] = useState(true);
  const [present, setPresent, refPresent] = useState(true);
  const [data, SetData] = React.useState();
  const [sections, setSections, refSections] = useState(
    props?.location?.state?.sections
  );
  const [currentSection, setCurrentSection, refCurrentSection] = useState(
    props?.location?.state?.sectionNumber
  );
  const [nextURL, setNextURL, refNextURL] = useState(
    props?.location?.state?.nextURL
  );
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuVisible, setMenuVisible] = useState(false);
  const [hasLastReadData, setLastReadData, refHasLastReadData] = useState(false);

  // console.log("Props inside SectionInfo", props.location.state)

  // const [lastReadLocatoin, setLastReadLocation, refLastReadLocation] = useState(
  //   {
  //     user: getUserId(),
  //     module_id: props?.location?.state?.sections[currentSection].id,
  //     module_type: "section",
  //     complete_scrolling_percent: null,
  //     channel: "web",
  //     page_link: "",
  //     index: null,
  //     scrollFromTop: 0
  //   }
  // );

  const sectionIdForNotes = props?.location?.state?.sectionIdForNotes;

  useEffect(() => {
    window.scrollTo(0, 0);
    // setLastReadLocation({ ...lastReadLocatoin, module_id: refSections.current[currentSection].id })
    fetchSpecificSection(sectionIdForNotes ? sectionIdForNotes : refSections.current[currentSection].id)
      .then((res) => {
        SetData(res.data);
        if (res.data.title) {
          setContent(res?.data);

          // setTitle(res.data.title);
          // recallTheContentPreviouslyRead(
          //   refSections.current[currentSection].id,
          //   "section"
          // )
          //   .then((res) => {
          //     if (res.data.length !== 0) {

          //       setLastReadData(true);
          //       document.getElementById("full_container").scrollTo({
          //         top: 1,
          //         behavior: "smooth", // Optionally, you can use 'auto' or 'smooth' for smooth scrolling
          //       });
          //       console.log("SET STATE after scroll");

          //       setTimeout(() => {
          //         const element = document.getElementById("full_container");
          //         scrollToPosition(
          //           element.scrollHeight,
          //           element.clientHeight,
          //           res.data[0].complete_scrolling_percent,
          //           "full_container"
          //         );
          //       }, 200);
          //       setLastReadLocation({
          //         ...lastReadLocatoin,
          //         complete_scrolling_percent:
          //           res.data[0].complete_scrolling_percent,
          //         id: res.data[0].id,
          //       });
          //       console.log("SET STATE");
          //     } else {
          //       document.getElementById("full_container").scrollTo({
          //         top: 0,
          //         behavior: "smooth", // Optionally, you can use 'auto' or 'smooth' for smooth scrolling
          //       });
          //       setLastReadData(false);
          //     }
          //   })
          //   .catch((err) => { });
        }
        else {
          setPresent(false);
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        setPresent(false);
        console.log(err);
      });
  }, [currentSection]);

  useEffect(() => {
    if (refCurrentSection?.current === refSections?.current?.length - 1) {
      fetchSections();
    }
  }, []);

  // useEffect(() => {
  //   const delay = 2000; // Delay in milliseconds (2 seconds in this example)

  //   const timerId = setTimeout(() => {
  //     if (refLastReadLocation.current.scrollFromTop > 1) {
  //       if (hasLastReadData) {
  //         console.log(refLastReadLocation.current);
  //         updateLastContentReadLocation(refLastReadLocation.current, refLastReadLocation.current.id).then((res) => {
  //         })
  //       } else {
  //         if (
  //           !hasLastReadData &&
  //           refLastReadLocation.current.complete_scrolling_percent
  //         ) {
  //           createLastContentReadLocation(refLastReadLocation.current).then(
  //             (res) => {
  //               setLastReadData(true);
  //               setLastReadLocation({ ...lastReadLocatoin, id: res.data.id });
  //             }
  //           );
  //         }
  //       }
  //     }
  //   }, delay);

  //   return () => {
  //     // Cleanup function to clear the timer if the component unmounts
  //     clearTimeout(timerId);
  //   };
  // }, [lastReadLocatoin.complete_scrolling_percent]);

  const onPreviousClick = () => {
    if (refCurrentSection.current >= 1) {
      setLastReadData(false);
      setCurrentSection(refCurrentSection.current - 1);
    }
  };

  const onNextClick = () => {
    setLastReadData(false);
    setCurrentSection(refCurrentSection.current + 1);

    if (
      refCurrentSection.current + 1 === refSections.current.length - 1 &&
      refNextURL.current
    ) {
      console.log(
        "IN IF",
        refCurrentSection.current + 1,
        refSections.current.length - 1
      );
      fetchSections();
    }
  };

  const fetchSections = () => {
    window.scrollTo(0, 0);
    fetchNextPageSections(refNextURL.current)
      .then((res) => {
        console.log("INDIVIDUAL SEC RES", res);
        setNextURL(res.data.next);
        let arr = res.data.results;
        arr.sort((a, b) => {
          return a.id - b.id;
        });
        setSections([...refSections.current, ...arr]);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleKeyDown = (e) => {
    const event = e.key;
    if (event === "ArrowRight") {
      if (refCurrentSection.current < refSections.current.length - 1) {
        onNextClick();
      }
    } else if (event === "ArrowLeft") {
      if (refCurrentSection.current !== 0) {
        onPreviousClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true);
  }, []);

  const onScrollListener = (event) => {
    // const { scrollTop, scrollHeight, clientHeight } = event.target;
    // const position = (scrollTop / (scrollHeight - clientHeight)) * 100;
    // console.log("position", position, scrollTop);
    // setLastReadLocation({
    //   ...lastReadLocatoin,
    //   complete_scrolling_percent: `${position.toFixed(2)}`,
    //   scrollFromTop: scrollTop
    // });
  };

  // const HtmlStructure = ({ data = [] }) => {
  //   return (
  //     data?.map((item, index) => (
  //       // <p key={index} className={classes.textContent} id={item.id}>
  //       <>
  //         {item.content} {"\n"} {"\n"}
  //       </>
  //       // </p>
  //     ))
  //   );
  // }

  const HtmlStructure = ({ data = [] }) => {
    return (
      data?.map(item => item.content.trim()).join('\n\n')
    );
  }



  return (
    <>
      {refSpinner.current ? (
        <FullScreenContent>
          <div className={classes.container}>
            <div className={classes.spinnerContainer}>
              <CircularProgress className={classes.spinner} />
            </div>
          </div>
        </FullScreenContent>
      ) : refPresent.current === false ? (
        <FullScreenContent>
          <div className={classes.container}>
            <h3 className={classes.heading} style={{ paddingTop: 50 }}>
              No data found
            </h3>
          </div>
        </FullScreenContent>
      ) : (
        <FullScreenContent onScrollListener={onScrollListener}>
          <div className={classes?.container}>

            <h2 className={classes?.heading}>
              Section ({content.title})
              <KnowledgeGraphButton
                data={data}
                pathname={"laws/Section"}
                previous={props?.location?.pathname}
                id={props?.match?.params?.id}
              />
            </h2>
            <hr className={classes.divider} />

            <div className={classes.content}>
              {/* <TextHighlighter
                module_type={'section'}
                text={refContent?.current?.content}
                module_id={refSections.current[currentSection].id}
              /> */}
              {/* {refContent.current.split("\n").map((item, index) => {
                return (
                  <p
                  key={index}
                    className={classes.textContent}
                  >
                    {parse(item)}
                  </p>
                );
              })} */}


              <TextHighlighter
                module_type={'section'}
                // text={refContent.current.length > 0 ? <HtmlStructure data={refContent.current} /> : ""}
                text={content.content}
                module_id={sectionIdForNotes ? sectionIdForNotes : refSections.current[currentSection].id}
              />

            </div>

          </div>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              className={classes.pagination}
              style={{
                justifyContent: currentSection === 0 ? "end" : "space-between",
              }}
            >
              {!(currentSection === 0) && (
                <div
                  className={classes.previous}
                  onClick={() => onPreviousClick()}
                >
                  {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className={classes.arrow}
                    >
                      <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg> */}
                  {/* Previous */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    className={classes.arrow}
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                  </svg>
                </div>
              )}
              {currentSection !== refSections.current.length - 1 && (
                <div className={classes.next} onClick={() => onNextClick()}>
                  {/* Next */}
                  {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className={classes.arrow}
                    >
                      <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                    </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    className={classes.arrow}
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </FullScreenContent>
      )}
    </>
  );
}

export default SectionInfo;
// const mapDispatchToProps = (dispatch) =>{
//     return {
//         setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
//     }
// }

// export default connect(
//     null,
//     mapDispatchToProps
//   )(IndividualAmendment)
