import React,{useEffect} from 'react'
import parse from 'html-react-parser'
import useState from 'react-usestateref'
// import { connect } from 'react-redux'
import {CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FullScreenContent from '../../components/MainContent/FullScreenContent';
import TextHighlighter from '../../components/textHighlighter/TextHighlighter';
import {fetchSpecificPreamble} from '../../Redux/APIs/FetchDetails/FetchPreambles'
// import MainContentSection from '../../components/MainContent/MainContentSection'
// import {setMenuItems} from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
// import {fetchSpecificAmendment} from '../../Redux/APIs/FetchAmendmentList/FetchAmendmentListActions'

const useStyles = makeStyles((theme) => ({
    heading:{
        textAlign:'center',
        paddingTop:30,
        fontWeight:600,
        paddingLeft:20,
        paddingRight:20,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]:{
            paddingTop:15,
            fontSize:19,
            marginBottom:-7,
        },
        [theme.breakpoints.up('sm')]:{
            paddingTop:25,
            fontSize:22,
        },
        [theme.breakpoints.up('md')]:{
            paddingTop:31,
            fontSize:23,
        },
        [theme.breakpoints.up('lg')]:{
            paddingTop:35,
            fontSize:26,
        }
    },
    divider:{
        width:'90%',
        align:'center',
        backgroundColor:'black'
    },
    content:{ 
        width:'80%',
        margin:'auto',
        // fontSize:17,
        paddingTop:1,
        [theme.breakpoints.up('xs')]:{
            marginTop:0,
            fontSize:15,
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:0,
            fontSize:16,
        },
        [theme.breakpoints.up('md')]:{
            marginTop:10,
            fontSize:17,
        }
    },
    container:{
        align:'center',
        
    },
    textContent:{
        marginTop:30,
    },
    spinner:{
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color:'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer:{
        display:"flex"
    },
   
}));


function PreambleInfo(props) {
    const classes = useStyles();

    const [title,setTitle,refTitle] = useState("");
    const [content,setContent,refContent] = useState("");
    const [spinner,setSpinner,refSpinner] = useState(true);
    const [present,setPresent,refPresent] = useState(true);


    // useEffect(()=>{
    //     props.setMenuItems([],4);
    // },[props])


    useEffect(()=>{
        window.scrollTo(0,0);
        fetchSpecificPreamble(props.match.params.id)
        .then((res)=>{
                // console.log('fetchSpecificPreamble_res',res.data)
                if(res.data.title){
                    setContent(res.data.content);
                    setTitle(res.data.title);
                } else {
                    setPresent(false);
                }
                setSpinner(false);
            })
            .catch((err)=>{
                setSpinner(false);
                setPresent(false);
                console.log(err);
            });

    },[])

console.log("refContent:",refContent)
    return (
        <>
        {
            refSpinner.current?
                <FullScreenContent>
                    <div className={classes.container}>
                    <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner}/></div>
                    </div>
                </FullScreenContent>:
                refPresent.current===false?
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h3 className={classes.heading} style={{paddingTop:50}}>No data found</h3>
                        </div>
                    </FullScreenContent>:
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>{parse(refTitle.current)}</h2>
                            <hr className={classes.divider}/>
                            <div className={classes.content}>

                            {/* {parse(refContent.current)} */}

                            {/* {refContent.current.split('\n').map((item,index)=>{
                                return (<p className={classes.textContent} key={index}>
                                    {item}
                                </p>)

                            })} */}
                             <TextHighlighter
                                module_type={'preamble'}
                                text={refContent.current}
                                module_id={props.match.params.id}
                            />
                            </div>
                        </div>
                        
                    </FullScreenContent>
        }
       
        </>
       
    )
}




export default PreambleInfo
// const mapDispatchToProps = (dispatch) =>{
//     return {
//         setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
//     }
// }

// export default connect(
//     null,
//     mapDispatchToProps
//   )(IndividualAmendment)
