import React, { useEffect } from "react";
import MainContentSection from "../../components/MainContent/MainContentSection";
import { makeStyles } from "@material-ui/core/styles";
import { setMenuItems } from "../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions";
import { connect } from "react-redux";
import useState from "react-usestateref";
import { fetchNextAmendmentsData, fetchNextPageAmendmentList, fetchSpecificAmendment } from "../../Redux/APIs/FetchAmendmentList/FetchAmendmentListActions";
import { CircularProgress } from "@material-ui/core";

import KnowledgeGraphButton from "../../components/KnowledgeGraph/KnowledgeGraphButton";
import { getLoginStatus, getUserId } from "../../config/cookiesInfo";
import { createLastContentReadLocation, recallTheContentPreviouslyRead, updateLastContentReadLocation } from "../../Redux/APIs/CommonNetworkCalls";
import { scrollToPosition } from "../../utilities/Utils";
import TextHighlighter from "../../components/textHighlighter/TextHighlighter";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    paddingTop: 30,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    // [theme.breakpoints.down('xs')]:{
    //     paddingTop:20,
    //     fontSize:25
    // }
    [theme.breakpoints.up("xs")]: {
      paddingTop: 15,
      fontSize: 22,
      marginBottom: -7,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 25,
      fontSize: 24,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 31,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 35,
      fontSize: 26,
    },
  },
  divider: {
    width: "90%",
    align: "center",
    backgroundColor: "black",
  },
  content: {
    width: "80%",
    margin: "auto",
    fontSize: 17,
    paddingTop: 1,
    [theme.breakpoints.up("xs")]: {
      marginTop: -10,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: -7,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  container: {
    align: "center",
  },
  textContent: {
    marginTop: 15,

    [theme.breakpoints.up("xs")]: {
      fontSize: 15,
      // marginBottom:3,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      // marginBottom:5,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
      // marginBottom:6,
    },
  },
  spinner: {
    margin: "auto",
    marginTop: "5%",
    // padding:'auto',
    color: "blue",
    // display:'flex',
    // justifyContent: 'center'
  },
  spinnerContainer: {
    display: "flex",
  },
  pagination: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  previous: {
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: "#white",
    color: "white",
    width: 36,
    height: 36,
    // paddingTop: 5,
    // paddingBottom: 5,
    // paddingRight: 8,
    // paddingLeft: 8,
    // borderRadius: 5,
    // minWidth: 80,
    borderRadius: "50%"
  },
  next: {
    fontWeight: "bold",
    cursor: "pointer",
    // backgroundColor: "white",
    color: "white",
    width: 36,
    height: 36,
    // paddingTop: 5,
    // paddingBottom: 5,
    // paddingRight: 8,
    // paddingLeft: 8,
    // borderRadius: 5,
    // minWidth: 80,
    textAlign: "center",
    borderRadius: "50%"
  },
  arrow: {
    fill: "#0D0D77",
    width: 24,
    height: 24,
  },
}));

function IndividualAmendment(props) {
  // console.log('IndividualAmendment_props==>',props.match)
  const classes = useStyles();

  const [title, setTitle, refTitle] = useState("");
  const [content, setContent, refContent] = useState("");
  const [spinner, setSpinner, refSpinner] = useState(true);
  const [present, setPresent, refPresent] = useState(true);
  const [amendments, setAmendments, refAmendments] = useState(
    props.location.state.amendments
  );
  const [currentAmendmentIndex, setCurrentAmendmentIndex, refCurrentAmendmentIndex] = useState(
    props.location.state.currentAmendmentIndex
  );

  const [hasLastReadData, setLastReadData, refHasLastReadData] =
    useState(false);

  const [lastReadLocatoin, setLastReadLocation, refLastReadLocation] = useState(
    {
      user: getUserId(),
      module_id: props.location.state.amendments[currentAmendmentIndex].id,
      module_type: "amendment",
      complete_scrolling_percent: null,
      channel: "web",
      page_link: "",
      index: null,
      scrollFromTop: 0
    }
  );

  const [nextURL, setNextURL] = useState(props.location.state.nextURL);
  const [data, setData] = React.useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setMenuItems([], 1);
  }, [props]);

  useEffect(() => {
    // setLastReadLocation({...lastReadLocatoin, module_id: refAmendments.current[currentAmendmentIndex].id})
    fetchSpecificAmendment(refAmendments.current[currentAmendmentIndex].id)
      .then((res) => {
        // setData(res.data)
        // console.log('fetchSpecificAmendment_res',res.data)
        if (res.data.title) {
          setContent(res.data.content);
          setTitle(res.data.title);
          setData(res.data);

          // if(getLoginStatus()){
          //   recallTheContentPreviouslyRead(
          //     refAmendments.current[refCurrentAmendmentIndex.current].id,
          //     "amendment"
          //   )
          //     .then((res) => {
          //       if (res.data.length !== 0) {
          //         setLastReadData(true);
          //         document.getElementById("container").scrollTo({
          //           top: 1,
          //           behavior: "smooth", // Optionally, you can use 'auto' or 'smooth' for smooth scrolling
          //         });
          //         console.log("HAS DATA", JSON.stringify(res.data, 0, 2))
          //         setTimeout(() => {
          //           const element = document.getElementById("container");
          //           scrollToPosition(
          //             element.scrollHeight,
          //             element.clientHeight,
          //             res.data[0].complete_scrolling_percent,
          //             "container"
          //           );
          //         }, 200);
          //         setLastReadLocation({
          //           ...lastReadLocatoin,
          //           complete_scrolling_percent:
          //             res.data[0].complete_scrolling_percent,
          //           id: res.data[0].id,
          //           module_id: res.data[0].module_id
          //         });

          //         console.log("SET STATE");
          //       } else {
          //         document.getElementById("container").scrollTo({
          //           top: 0,
          //           behavior: "smooth", // Optionally, you can use 'auto' or 'smooth' for smooth scrolling
          //         });
          //         setLastReadData(false);
          //       }
          //     })
          //     .catch((err) => {});
          // }

        } else {
          setPresent(false);
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        setPresent(false);
        console.log(err);
      });
  }, [refCurrentAmendmentIndex.current]);

  // useEffect(() => {
  //   const delay = 2000; // Delay in milliseconds (2 seconds in this example)

  //   const timerId = setTimeout(() => {
  //     if (refLastReadLocation.current.scrollFromTop > 1) {
  //     if (refHasLastReadData.current) {
  //       console.log(lastReadLocatoin);
  //       updateLastContentReadLocation(refLastReadLocation.current, refLastReadLocation.current.id).then((res) => {
  //       })
  //     } else {
  //       if (
  //         !hasLastReadData &&
  //         refLastReadLocation.current.complete_scrolling_percent
  //       ) {
  //         createLastContentReadLocation(refLastReadLocation.current).then(
  //           (res) => {
  //             setLastReadData(true);
  //             setLastReadLocation({ ...lastReadLocatoin, id: res.data.id });
  //           }
  //         );
  //       }
  //     }
  //   }
  //   }, delay);

  //   return () => {
  //     // Cleanup function to clear the timer if the component unmounts
  //     clearTimeout(timerId);
  //   };
  // }, [lastReadLocatoin.complete_scrolling_percent]);

  const onPreviousClick = () => {
    if (refCurrentAmendmentIndex.current >= 1) {
      setLastReadData(false);
      setCurrentAmendmentIndex(refCurrentAmendmentIndex.current - 1);
    }
  };

  const onNextClick = () => {
    setLastReadData(false);
    setCurrentAmendmentIndex(refCurrentAmendmentIndex.current + 1);
    if (refCurrentAmendmentIndex.current + 1 === amendments.length - 1 && nextURL) {
      fetchNextAmendmentsData(nextURL)
        .then((res) => {
          setNextURL(res.data.next)
          let arr = res.data.results;
          arr.sort((a, b) => {
            return a.id - b.id;
          })
          setAmendments([...refAmendments.current, ...arr])

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleKeyDown = (e) => {
    const event = e.key
    if (event === "ArrowRight") {
      if (refCurrentAmendmentIndex.current < refAmendments.current.length - 1) {
        onNextClick()
      }
    } else if (event === "ArrowLeft") {
      if (refCurrentAmendmentIndex.current !== 0) {
        onPreviousClick()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);
  }, [])

  const onScrollListener = (event) => {
    // if(getLoginStatus()){
    //   const { scrollTop, scrollHeight, clientHeight } = event.target;
    // const position = (scrollTop / (scrollHeight - clientHeight)) * 100;
    // setLastReadLocation({
    //   ...lastReadLocatoin,
    //   complete_scrolling_percent: `${position.toFixed(2)}`,
    //   scrollFromTop: scrollTop
    // });
    // }
  };

  let isLoggedIn = getLoginStatus();

  return (
    <>
      {refSpinner.current ? (
        <MainContentSection>
          <div className={classes.container}>
            <div className={classes.spinnerContainer}>
              <CircularProgress className={classes.spinner} />
            </div>
          </div>
        </MainContentSection>
      ) : refPresent.current === false ? (
        <MainContentSection>
          <div className={classes.container}>
            <h3 className={classes.heading} style={{ paddingTop: 50 }}>
              No data found
            </h3>
          </div>
        </MainContentSection>
      ) : (
        <MainContentSection onScrollListener={onScrollListener}>
          <div className={classes.container}>
            <h2 className={classes.heading}>
              {refTitle.current}
              <KnowledgeGraphButton
                data={data}
                pathname={"amendments"}
                previous={props.location.pathname}
                id={props.match.params.id}
              />
            </h2>
            <hr className={classes.divider} />


            <div style={{ top: '50%', position: 'fixed', marginRight: 10, marginLeft: 10, display: 'flex', justifyContent: refCurrentAmendmentIndex.current === 0 ? "end" : "space-between", flexDirection: 'row', width: '-webkit-fill-available' }}>
              {!(refCurrentAmendmentIndex.current === 0) && (
                <div
                  className={classes.previous}
                  onClick={() => onPreviousClick()}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className={classes.arrow}>
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
                </div>
              )}

              {refCurrentAmendmentIndex.current !== amendments.length - 1 && (
                <div className={classes.next} onClick={() => onNextClick()}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className={classes.arrow}>
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
                </div>
              )}
            </div>

            <div className={classes.content}>

              {/* {isLoggedIn ? ( */}
                <TextHighlighter
                  module_type={'amendment'}
                  text={refContent.current}
                  module_id={amendments[refCurrentAmendmentIndex.current].id}
                />
              {/* ) : (
                refContent.current.split("\n").map((item, index) => (
                  <p className={classes.textContent} key={index}>
                    {item}
                  </p>
                ))
              )} */}

            </div>
          </div>
        </MainContentSection>
      )}
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
  };
};

export default connect(null, mapDispatchToProps)(IndividualAmendment);
