import React from 'react'
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Box, } from '@material-ui/core';
import { makeStyles, } from "@material-ui/core/styles";
import { connect } from 'react-redux'
import './MainContentSection.css'
import {displayLeftSidebar} from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions'
const useStyles = makeStyles((theme) => ({

    containerStyle:{
        position:'relative',
        width:'100%'
    }

}));


function FullScreenContent(props) {
    // console.log('FullScreenContent_props',props)
    const classes = useStyles();

    React.useEffect(()=>{
        props.displayLeftSidebarFn(false);
        return ()=>{
            props.displayLeftSidebarFn(true);
        }
    },[])

    // mainContentStyle

    const handleOnScroll = (e) => {
        // if (props.hasCallBack && props.hasCallBack === "yes") {
          if(props.onScrollListener){
            props.onScrollListener(e)
          }
        // }
        //   const { scrollTop, scrollHeight, clientHeight } = e.target;
        //   const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100);
        //   console.log(e);
        //   console.log("ON SCROLL", position, scrollTop, scrollHeight, clientHeight);
      };

    return (
    <React.Fragment>
        <CssBaseline />
            <Container id="full_container" className={`${classes.containerStyle} mainContentStyle`} style={{maxWidth:"100%"}} onScroll={handleOnScroll}>
                <Box  color="text.primary" style={{backgroundColor:'#FFFFFF',minHeight:'100vh',paddingBottom:'50px'}}>
                {props.children}
                </Box>
            </Container>
      </React.Fragment>

    )
}

// export default FullScreenContent


const mapDispatchToProps = (dispatch) =>{
    return {
        displayLeftSidebarFn : (value) => dispatch(displayLeftSidebar(value)),
    }
  }
  
  
  // export default RightSideBar
  
  export default connect(
    null,
    mapDispatchToProps
  )(FullScreenContent)