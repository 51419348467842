export const convertPercentageToPixels = (
  sHeight,
  cHeight,
  completedPercentage
) => {
  console.log((completedPercentage / 100) * (sHeight - cHeight));
  return (completedPercentage / 100) * (sHeight - cHeight);
};

export const scrollToPosition = (sHeight, cHeight, completedPercentage, id="container") => {
  document.getElementById(id).scrollTo({
    top: convertPercentageToPixels(sHeight, cHeight, completedPercentage),
    behavior: "smooth", // Optionally, you can use 'auto' or 'smooth' for smooth scrolling
  });
};
