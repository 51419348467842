// import {FETCH_RESOURCE_SUCCESS,FETCH_RESOURCE_FAILURE} from "./ResourceMenuItemsTypes"

const initialState = {
    data: [
        {
            name: "Constituent Assembly Debates",
            nestedItems: [
                {
                    name: "Year 1946 (11)",
                    nestedItems: [],
                    pageLink: '/resource/constituentAssemblyDebates',
                    apiLink: '',
                    data: {
                        year: '1946'
                    }
                },
                {
                    name: "Year 1947 (42)",
                    nestedItems: [],
                    pageLink: '/resource/constituentAssemblyDebates',
                    apiLink: '',
                    data: {
                        year: '1947'
                    }
                },
                {
                    name: "Year 1948 (52)",
                    nestedItems: [],
                    pageLink: '/resource/constituentAssemblyDebates',
                    apiLink: '',
                    data: {
                        year: '1948'
                    }
                },
                {
                    name: "Year 1949 (154)",
                    nestedItems: [],
                    pageLink: '/resource/constituentAssemblyDebates',
                    apiLink: '',
                    data: {
                        year: '1949'
                    }
                },
                {
                    name: "Year 1950 (1)",
                    nestedItems: [],
                    pageLink: '/resource/constituentAssemblyDebates',
                    apiLink: '',
                    data: {
                        year: '1950'
                    }
                }
            ],
            pageLink: '',
            apiLink: '',
            data: {}
        },
        {
            name: "Supreme Court Judgements",
            nestedItems: [
                {
                    name: "Year 2016 (742)",
                    nestedItems: [],
                    pageLink: '/resource/supremeCourtJudgements',
                    apiLink: '',
                    data: {
                        year: '2016'
                    }
                },
                {
                    name: "Year 2017 (830)",
                    nestedItems: [],
                    pageLink: '/resource/supremeCourtJudgements',
                    apiLink: '',
                    data: {
                        year: '2017'
                    }
                },
                {
                    name: "Year 2018 (1057)",
                    nestedItems: [],
                    pageLink: '/resource/supremeCourtJudgements',
                    apiLink: '',
                    data: {
                        year: '2018'
                    }
                },
                {
                    name: "Year 2019 (1250)",
                    nestedItems: [],
                    pageLink: '/resource/supremeCourtJudgements',
                    apiLink: '',
                    data: {
                        year: '2019'
                    }
                },
                {
                    name: "Year 2020 (688)",
                    nestedItems: [],
                    pageLink: '/resource/supremeCourtJudgements',
                    apiLink: '',
                    data: {
                        year: '2020'
                    }
                },
                {
                    name: "Year 2021 (834)",
                    nestedItems: [],
                    pageLink: '/resource/supremeCourtJudgements',
                    apiLink: '',
                    data: {
                        year: '2021'
                    }
                },
                {
                    name: "Year 2022 (97)",
                    nestedItems: [],
                    pageLink: '/resource/supremeCourtJudgements',
                    apiLink: '',
                    data: {
                        year: '2022'
                    }
                },
            ],
            pageLink: '/resource/supremeCourtJudgements',
            apiLink: '',
            data: {}
        },
    ]
}

const resourceMenuItemsReducer = (state = initialState, action) => {
    return state;
}


export default resourceMenuItemsReducer


























// const resourceMenuItemsReducer = (state = initialState, action) =>{

//     switch(action.type){
//         case FETCH_RESOURCE_FAILURE:
//             return {
//                 data : [],
//                 error : action.error
//             }
//         case FETCH_RESOURCE_SUCCESS:
//             return {
//                 data : action.payload,
//                 error : ''
//             }
//         default :
//             return state;

//     }
// }


