import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'


export const fetchSectionsUnderChapter = (lawId, partId, chapterId,id="") => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}section/?law=${lawId}&part=${partId}&chapter=${chapterId}`,
        // axios.get(`${HOST}newsection/?id=${id}&law=${""}&part=${partId}&chapter=${chapterId}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}

export const fetchSectionsUnderSubHeading = (subheadingId) => {
    return new Promise((resolve, reject)=>{
        // axios.get(`${HOST}section/?law=${lawId}&part=${partId}&chapter=${chapterId}`,
        axios.get(`${HOST}newsection/?sub_heading=${subheadingId}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}

export const fetchNextPageSections = (URL) => {
    return new Promise((resolve, reject)=>{
        axios.get(URL,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}
