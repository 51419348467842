import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'


export const fetchParts = (id) => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}part/?law=${id}`,
        // axios.get(`${HOST}newpart/?law=${id}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}
