import React from "react";
import { Card } from "react-bootstrap";
import List from '@material-ui/core/List';
import { useHistory } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import Login from "../../components/Login/Login";
import { getLoginStatus } from "../../config/cookiesInfo";
import { makeStyles, fade } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, ListItem } from "@material-ui/core";
import FullScreenContent from "../../components/MainContent/FullScreenContent";
import { fetchSpecificSection } from "../../Redux/APIs/FetchDetails/FetchSections";
import { fetchAllNotesAndHighlights } from "../../Redux/APIs/TextHighlighter/TextHighlighterAction";
import { fetchSpecificAmendment } from "../../Redux/APIs/FetchAmendmentList/FetchAmendmentListActions";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    marginLeft: "-3px",
    fontFamily: `Roboto,Helvetica,Arial,sans-serif`,
    color: "#FFFF",
    [theme.breakpoints.up("xs")]: {
      paddingTop: 0,
      fontSize: 22,
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
      fontSize: 24,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 0,
      fontSize: 26,
    },
  },
  spinnerContainer: {
    textAlign: "center",
    paddingBottom: "20px"
  },
  content: {
    width: "85%",
    margin: "auto",
    paddingTop: 20,

    [theme.breakpoints.up("xs")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 2,
    },
  },
  container: {
    align: "center",
  },
  spinner: {
    margin: "auto",
    marginTop: "5%",
    marginLeft: '10px',
    color: 'blue',
  },
  adjustWidth: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "45%",
    },
  },
  labelStyle: {
    color: "gray",
    fontWeight: 500,
    lineHeight: 1.43,
    marginBottom: 15,
    letterSpacing: '0.01071em',
    fontFamily: `Roboto,Helvetica,Arial,sans-serif`,
    [theme.breakpoints.up("xs")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
    },
  },
  valueStyle: {
    display: "flex",
    padding: "30",
    fontWeight: 400,
    marginBottom: 15,
    letterSpacing: '0.01071em',
    fontFamily: `Roboto,Helvetica,Arial,sans-serif`,
    [theme.breakpoints.up("xs")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
    },
  },
  inputSearch: {
    backgroundColor: fade(theme.palette.common.white, 0.20),
    width: 230,
    height: 35,
    outline: 'none',
    border: 0,
    color: 'white',
    paddingLeft: 15,
    [theme.breakpoints.up('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
}));

const UserNotes = () => {
  const history = useHistory();
  const classes = useStyles();

  const [hasMore, setHasMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [notesData, setNotesData] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [offset, setOffset] = React.useState(notesData.length === 0 ? 0 : notesData.length - 20);

  React.useEffect(() => {
    if (notesData.length === 0 && getLoginStatus()) {
      setLoading(true);
      fetchAllNotesAndHighlights(offset)
        .then((res) => {
          let arr = res.data.results;

          if (res.data.next === null) {
            setHasMore(false);
          }
          setNotesData((prev) => [...prev, ...arr]);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error fetching Highlights and Notes List", err);
        });
    }
  }, []);

  React.useEffect(() => {
    setLoading(true);
    setOffset(0)
    fetchAllNotesAndHighlights(offset, searchText)
      .then((res) => {
        let arr = res.data.results;

        if (res.data.next === null) {
          setHasMore(false);
        }
        setNotesData(arr);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error fetching Highlights and Notes List", err);
      });
  }, [searchText])

  const getNextData = (offset) => {
    setOffset(offset)
    fetchAllNotesAndHighlights(offset, searchText)
      .then((res) => {
        let arr = res.data.results;

        if (res.data.next === null) {
          setHasMore(false);
        }
        setNotesData((prev) => [...prev, ...arr]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error fetching Highlights and Notes List", err);
      })
  }

  const fetchSection = (id, sectionTitle) => {
    if (!getLoginStatus()) {
      history.push("/login");
    } else {
      fetchSpecificSection(id)
        .then((res) => {
          history.push(`/laws/individualLaw/section/${id}`, {
            sections: [res?.data?.results],
            currentSection: res.data,
            sectionNumber: 0,
            nextURL: null,
            sectionTitle: sectionTitle,
            sectionIdForNotes: id, // passing section id separately
          });
        })
        .catch((err) => { });
    }
  };

  const fetchAmendment = (id) => {
    fetchSpecificAmendment(id)
      .then((res) => {
        // setData(res.data)
        // console.log('fetchSpecificAmendment_res',res.data)
        // if (res) {
        history.push(`/constitution/amendments/${id}`, { amendments: [res.data], currentAmendmentIndex: 0, nextURL: null })
        // } else {
        // }
      })
      .catch((err) => {

      });
  }

  const getNavigatingUrl = (module_type, module_id) => {

    switch (module_type) {
      case "debate":
        return `/resource/constituentAssemblyDebates/${module_id}`;
      case "section":
        return `/laws/individualLaw/section/${module_id}`;
      case "schedule":
        return `/laws/individualLaw/schedule/${module_id}`;
      case "judgement":
        return `/resource/supremeCourtJudgements/${module_id}`;
      case "article":
        return `/constitution/parts/chapters/articles/${module_id}`;
      case "amendment":
        return `/constitution/amendments/${module_id}`;
      case "coi_schedule":
        return `/constitution/schedules/${module_id}`;
      case "preamble":
        return `/laws/individualLaw/preamble/${module_id}`
      default:
        return;
    }
  };

  return (
    <>
      {
        !getLoginStatus() ?
          <Login pathToGo={'/user-notes'} /> :
          <FullScreenContent>
            <div className={classes.container}>
              <div className={classes.content}>
                <Card style={{ marginTop: 10, border: "1px solid rgba(0,0,0,.125)" }}>
                  <Card.Header style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#0d0d77" }}>
                    <h2 className={classes.heading}>Notes and Highlights</h2>
                    <input
                      onChange={(e) => setSearchText(e.target.value)}
                      value={searchText}
                      type='text'
                      className={`${classes.inputSearch} m_inputSearch`}
                      placeholder='Search Notes...'
                      onKeyUp={(e) => {
                        if (e.key === 'Enter')
                          fetchAllNotesAndHighlights()
                      }}
                    />
                  </Card.Header>
                  <Card.Body style={{ padding: 0, margin: 0 }}>
                    {
                      loading ?
                        <CircularProgress className={classes.spinner} />
                        :
                        notesData.length === 0 ?
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "calc(100vh - 180px)" }}>
                            <h4>Added notes will appear here</h4>
                          </div>
                          :
                          <InfiniteScroll
                            dataLength={notesData.length}
                            next={() => {
                              getNextData(offset + 20);
                            }}
                            hasMore={hasMore}
                            loader={<div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>}
                            style={{ overflow: 'hidden' }}
                          >
                            <List>
                              {
                                notesData?.map((note, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <ListItem
                                        style={{ cursor: "pointer" }}
                                        key={index}
                                        onClick={() => {
                                          const url = getNavigatingUrl(
                                            note.module_type,
                                            note.module_id
                                          );
                                          if (note.module_type === "section") {
                                            fetchSection(note.module_id, note.title);
                                          } else if (note.module_type === "amendment") {
                                            fetchAmendment(note.module_id)
                                          } else {
                                            history.push(url, note.module_id);
                                          }
                                        }}
                                      >
                                        <div style={{ marginTop: 10 }}>
                                          <span className={classes.valueStyle}>
                                            <b> {note.title} </b>
                                          </span>
                                          <span className={classes.labelStyle}>{note.note}</span>
                                        </div>
                                      </ListItem>
                                      {index < notesData.length - 1 && (
                                        <Divider />
                                      )}
                                    </React.Fragment>
                                  )
                                }
                                )}
                            </List>
                          </InfiniteScroll>
                    }
                  </Card.Body>
                </Card>
              </div>
            </div>
          </FullScreenContent>
      }
    </>
  );
};

export default UserNotes;
