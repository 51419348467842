// module.exports={
//     HOST : "https://articlenumber21.com/coi/",
// };

// export const HOST = "http://172.105.253.22:8000/coi/";

export const HOST = "https://api.articlenumber21.com/coi/";

// export const HOST = "https://poodle-nearby-chigger.ngrok-free.app/coi/"
// export const HOST = "http://192.168.1.25:8080/coi/"

// export const HOST = "https://whippet-bright-uniformly.ngrok-free.app/coi/"

// export const HOST = "http://192.168.1.25:8090/coi/"

// export const HOST = "http://192.168.1.25:8080/coi/";


// if(process.env.NODE_ENV == 'production'){
//     module.exports={
//         HOST : "https://articlenumber21.com/coi/",
//     };
// }










