import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Button, Snackbar, Fab, Icon } from '@material-ui/core';
import TrendingUpTwoToneIcon from '@material-ui/icons/TrendingUpTwoTone';
import graph from '../../images/graph.svg'
// import graph1 from '../../images/graph1.svg'

// C:\Users\anc\Desktop\25-10-21\Projects\frontend_test\coi-webapp\src\images\graph.svg
const useStyles = makeStyles(() => ({
    title: {
        fontSize: 12,
        textAlign: 'center',
    },
    knowledgegraphIcon: {
        cursor: 'pointer',
        // color: '#0D0D77',
        // color: 'grey',
        fontSize: 25,
        // marginLeft: 12,
        verticalAlign: 'center',
        position: 'relative',
        top: -6,
        // transform:'rotate(-50deg)'

        // top: -10,
    }
}));
const KnowledgeGraphButton = (props) => {
    const classes = useStyles();
    const history = useHistory();

    let title = <span className={classes.title}> Knowledge Graph</span>

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const navigateToKnowledgeGraph = () => {
        history.push(`/constitution/knowledgegraph/${props?.pathname}/${props?.id}`
            // { data: props.data, previous: props.previous }
        )
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [hover, setHover] = React.useState(false);

    const handleMouseOut = () => {
        setHover(false);
    };

    const handleMouseIn = () => {
        setHover(true);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <Tooltip
                open={hover}
                onClose={handleMouseOut}
                onOpen={handleMouseIn}
                title={title}
                arrow>
                {/* <Button
                        className={classes.knowledgegraphIcon}
                        variant="contained"
                        onClick={() => {
                            navigateToKnowledgeGraph()
                        }}
                    >
                        <TrendingUpTwoToneIcon
                            style={{ fontSize: 20 }}
                        />
                    </Button> */}

                <Icon>
                    <img
                        // src={graph1}
                        src={graph}
                        className={classes.knowledgegraphIcon}
                        height={40} width={40}
                        onClick={() => {
                            navigateToKnowledgeGraph()
                        }}
                        alt='Knowledge Graph'
                    />
                </Icon>
            </Tooltip>
        </div>
        // {/* <div>
        //     <Button
        //         variant="contained"
        //         color="primary"
        //         onClick={() => {
        //             navigateToKnowledgeGraph()
        //         }}
        //         onMouseOver={handleMouseIn}
        //         onMouseOut={handleMouseOut}
        //     >
        //         {hover ? title : <TrendingUpTwoToneIcon />}
        //     </Button>
        // </div> */}

    )
}

export default KnowledgeGraphButton
