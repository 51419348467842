import React, { useEffect } from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { connect } from 'react-redux'
import useState from 'react-usestateref'
import { fetchSpecificArticle } from '../../Redux/APIs/FetchArticleList/FetchArticleListActions'
import { CircularProgress } from '@material-ui/core';
import KnowledgeGraphButton from '../../components/KnowledgeGraph/KnowledgeGraphButton'
import TextHighlighter from '../../components/textHighlighter/TextHighlighter';
import { getLoginStatus } from '../../config/cookiesInfo';



const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        display: 'flex',
        justifyContent:'center',
        alignSelf: 'center',
        
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {
        width: '80%',
        margin: 'auto',
        fontSize: 17,
        paddingTop: 1,
        [theme.breakpoints.up('xs')]: {
            marginTop: -10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }
    },
    container: {
        align: 'center',

    },
    textContent: {
        marginTop: 15,

        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            // marginBottom:3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
            // marginBottom:5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 17,
            // marginBottom:6,
        },
    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },

}));


function IndividualArticle(props) {
    // console.log('IndividualArticle_props',props)
    const classes = useStyles();
    const loginStatus = getLoginStatus()
    const [title, setTitle, refTitle] = useState("");
    const [description, setDescription, refDescription] = useState("");
    const [content, setContent, refContent] = useState("");
    const [spinner, setSpinner, refSpinner] = useState(true);
    const [present, setPresent, refPresent] = useState(true);
    const[data,SetData]=React.useState();


    useEffect(() => {
        window.scrollTo(0, 0);
        props.setMenuItems([], 1);
    }, [props])


    // console.log('IndividualArticle_props',props.location.state._source)
    useEffect(() => {
        fetchSpecificArticle(props.match.params.id)
            .then((res) => {
                // console.log('fetchSpecificArticle_res', res.data)
                SetData(res.data)
                if (res.data.title) {
                    setContent(res.data.content);
                    setTitle(res.data.title);
                    setDescription(res.data.description)
                } else {
                    setPresent(false);
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                setPresent(false);
                console.log(err);
            });

    }, [])


    return (
        <>
            {
                refSpinner.current ?
                    <MainContentSection>
                        <div className={classes.container}>
                            <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>
                        </div>
                    </MainContentSection> :
                    refPresent.current === false ?
                        <MainContentSection>
                            <div className={classes.container}>
                                <h3 className={classes.heading} style={{ paddingTop: 50 }}>No data found</h3>
                            </div>
                        </MainContentSection> :
                        <MainContentSection>
                            <div className={classes.container}>
                                <h2 className={classes.heading}>{refTitle.current}
                                <KnowledgeGraphButton data={data} pathname={'articles'} previous={props.location.pathname} id={props.match.params.id}/>
                                </h2>
                                {/* <h3 className={classes.heading}>{refTitle.current}</h3> */}
                                <hr className={classes.divider} />
                                <div className={classes.content}>

                                    <p className={classes.textContent}><b>{refDescription.current}</b></p>
                                  
                                    <TextHighlighter
                                        module_type={'article'}
                                        text={refContent.current}
                                        module_id={props.match.params.id}
                                    />
                                    {/* {refContent.current.split('\n').map((item, index) => {
                                        return (<p className={classes.textContent} key={index}>
                                            {item}
                                        </p>)

                                    })} */}

                                </div>
                            </div>

                        </MainContentSection>
            }

        </>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(IndividualArticle)
