import React, { useEffect } from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'

import useState from 'react-usestateref'
import FullScreenContent from '../../components/MainContent/FullScreenContent';
import { fetchSectionsUnderChapter, fetchSectionsUnderSubHeading } from '../../Redux/APIs/FetchDetails/FetchSectionsUnderChapter'
import { logoutUser } from '../../config/firebaseAuthentication';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {
        width: '80%',
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            marginTop: -10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }
    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        color: 'blue',
    },
    spinnerContainer: {
        display: "flex"
    },
    itemStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            marginBottom: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 17.5,
            marginBottom: 5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginBottom: 6,
        },
    }

}));


const SectionList = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [sections, setSections, refSections] = useState([]);
    const [nextURL, setNextURL] = useState(null)

    let chapterId = props.location.state ? props.location.state.chapterId : '';
    let partId = props.location.state ? props.location.state.partId : '';
    let lawId = props.location.state ? props.location.state.lawId : '';
    let has_section = props.location.state ? props.location.state.has_section : '';
    let has_subheading = props.location.state ? props.location.state.has_subheading : '';
    let subheadingId = props.location.state ? props.location.state.subheadingId : ''; // Passed from ChaperList

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchSectionsUnderChapter(lawId, partId, chapterId)
            .then((res) => {
                // console.log('fetchSectionsUnderChapter_res',res)
                setNextURL(res.data.next)
                let arr = res.data.results;
                arr.sort((a, b) => {
                    return a.id - b.id;
                })
                setSections(arr)

            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    logoutUser()
                    history.push("/")
                }
            });
    }, [])


    useEffect(() => {
        if (subheadingId) {
            window.scrollTo(0, 0);
            fetchSectionsUnderSubHeading(subheadingId)
                .then((res) => {
                    // console.log('fetchSectionsUnderChapter_res',res)
                    setNextURL(res.data.next)
                    let arr = res.data.results;
                    arr.sort((a, b) => {
                        return a.id - b.id;
                    })
                    setSections(arr)

                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        logoutUser()
                        history.push("/")
                    }
                });
        }
    }, [])

    return (

        <>
            {
                !props.location.state ?
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h3 className={classes.heading} style={{ paddingTop: 50 }}>No data found</h3>
                        </div>
                    </FullScreenContent> :
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>Sections</h2>
                            <hr className={classes.divider} />
                            <div className={classes.content}>
                                {
                                    refSections.current.length === 0 ?
                                        <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div> :
                                        <List>
                                            {
                                                refSections.current.map((info, index) => {
                                                    // console.log('lawsectionlist_info', info)
                                                    return (
                                                        <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                            history.push(`/laws/individualLaw/section/${info.id}`,
                                                                {
                                                                    sectionTitle: info.title,
                                                                    chapterId: info.chapter,
                                                                    sectionId: info.id,
                                                                    sections: refSections.current,
                                                                    currentSection: info,
                                                                    sectionNumber: index,
                                                                    nextURL: nextURL
                                                                })
                                                        }}>
                                                            {/* <h5><b>Section {index+1} ({info.title})</b></h5> */}
                                                            <h5 className={classes.itemStyle}>
                                                                <b> {info.section}</b>
                                                                ({info.title})
                                                            </h5>
                                                            {/* {info.title} */}
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                }

                            </div>
                        </div>
                    </FullScreenContent>
            }
        </>
    )
}

export default SectionList







