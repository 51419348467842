import React, { useEffect } from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';

import InfiniteScroll from 'react-infinite-scroll-component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import useState from 'react-usestateref'

import { fetchLawList, fetchLawListSuccess, setLawOffset, setLawData, setLawLoad } from '../../Redux/APIs/FetchLawList/FetchLawListActions'
//fetchAmendmentListSuccess
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { getLoginStatus } from '../../config/cookiesInfo'
import Login from '../../components/Login/Login';
import FullScreenContent from '../../components/MainContent/FullScreenContent';

// import {displayLeftSidebar} from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions'

// import {getLoginStatus} from '../../config/cookiesInfo'
import { firebaseAnalytics } from '../../config/firebase-config'
import { logoutUser } from '../../config/firebaseAuthentication';




const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {

        // backgroundColor:'red',
        width: '80%',
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            marginTop: -10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }

    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },
    itemStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            marginBottom: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 17.5,
            marginBottom: 5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginBottom: 6,
        },
    }

}));


const Laws = ({ lawList, lawListOffset, lawListLoad, ...props }) => {

    let { fetchLawList, fetchLawListSuccess, setLawData, setLawLoad, setLawOffset } = props;

    const classes = useStyles();

    const [data, setData, refData] = useState(lawList);
    const [offset, setOffset, refOffset] = useState(lawList.length === 0 ? 0 : lawList.length - 20);
    const [load, setLoad] = useState(lawListLoad);

    const history = useHistory();

    useEffect(() => {
        // firebaseAnalytics.logEvent(`LawsListPage_visited`);
    }, [])



    useEffect(() => {
        if (refData.current.length === 0 && getLoginStatus()) {
            fetchLawList(refOffset.current)
                .then((res) => {
                    if (load === true) {
                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        if (res.data.next === null) {
                            setLoad(false);
                        }

                        setData([...refData.current, ...arr])
                        setLawData(refData.current);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        logoutUser()
                        history.push("/")
                    }
                });

        }
        // fetchAmendmentList,setData,load,refData,refOffset
    }, [])



    const getNextData = (offset) => {
        setOffset(offset)
        fetchLawList(refOffset.current)
            .then((res) => {
                if (load === true) {
                    let arr = res.data.results;
                    arr.sort((a, b) => {
                        return a.id - b.id;
                    })
                    if (res.data.next === null) {
                        setLoad(false);
                    }
                    setData([...refData.current, ...arr])
                    setLawData(refData.current);
                }



            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    logoutUser()
                    history.push("/")
                }
            });

    }

    // console.log("PROPS IN LAWS==>",props)
    // console.log("lawList IN LAWS==>",lawList)


    // or redirect

    return (

        <>
            {
                !getLoginStatus() ?
                    <Login pathToGo={'/laws'} /> :
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>Laws</h2>
                            <hr className={classes.divider} />
                            <div className={classes.content}>

                                {
                                    refData.current.length === 0 ?
                                        <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div> :
                                        <InfiniteScroll
                                            dataLength={refData.current.length}
                                            next={() => {
                                                getNextData(refOffset.current + 20);
                                            }}
                                            hasMore={load}
                                            loader={<div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>}
                                            style={{ overflow: 'hidden' }}>

                                            <List>
                                                {
                                                    refData.current.map((info, index) => {
                                                        // console.log('law_info', info)
                                                        return (
                                                            <ListItem
                                                                style={{ cursor: 'pointer' }}
                                                                key={index}
                                                                onClick={() => {
                                                                    setLawData(refData.current);
                                                                    history.push('/laws/individualLaw',
                                                                        {
                                                                            title: info.title,
                                                                            id: info.id,
                                                                            // srno:info.id,
                                                                            description: info.description,
                                                                            link: info.link,
                                                                            year: info.year,
                                                                            has_part: info.has_part,
                                                                            has_chapter: info.has_chapter,
                                                                            has_schedule: info.has_schedule,
                                                                            has_preamble: info.has_preamble,
                                                                        })
                                                                }}>
                                                                <h5 className={classes.itemStyle}>
                                                                    <b>
                                                                        {info.title}
                                                                    </b>
                                                                </h5>
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </List>
                                        </InfiniteScroll>
                                }

                            </div>
                        </div>
                    </FullScreenContent>
            }
        </>
    )
}

// export default Amendments
const mapStateToProps = (state) => {

    let { fetchLawList: { data, offset, loadMore } } = state;

    // console.log("DATA IN STATEPROPS ==>",data)

    return {
        lawList: data,
        lawListOffset: offset,
        lawListLoad: loadMore,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        fetchLawList: (offset) => dispatch(fetchLawList(offset)),
        fetchLawListSuccess: (list, offset, load) => dispatch(fetchLawListSuccess(list, offset, load)),
        // setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
        setLawData: (data) => dispatch(setLawData(data)),
        setLawLoad: (data) => dispatch(setLawLoad(data)),
        setLawOffset: (data) => dispatch(setLawOffset(data)),

        // displayLeftSidebarFn : (value) => dispatch(displayLeftSidebar(value)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Laws)






