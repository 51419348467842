import React, { useEffect } from 'react'
import { fetchRecentJudgements, fetchJudgementList, fetchJudgementListSuccess, setJudgementData, setJudgementLoad, setJudgementOffset, setJudgementFrom, setJudgementTo, fetchJudgementYearWise, setJudgementLanguage, setJudgementYear } from '../../Redux/APIs/FetchJudgementList/FetchJudgementListActions'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import useState from 'react-usestateref'

import InfiniteScroll from 'react-infinite-scroll-component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import parse from 'html-react-parser'
import { CircularProgress, TextField, Button, Grid, Paper } from '@material-ui/core';

import { firebaseAnalytics } from '../../config/firebase-config'
//fetchAmendmentListSuccess
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { logoutUser } from '../../config/firebaseAuthentication';
import { Today } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {

        // backgroundColor:'red',
        width: '80%',
        margin: 'auto'

    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },
    noDataDiv: {
        fontSize: 20,
        fontWeight: 600

    }

}));


const Judgements = ({ language_type, judgementList, judgementOffset, judgementLoad, judgementListLanguage, judgementListYear, ...props }) => {

    const history = useHistory();

    let { fetchJudgementList, fetchJudgementYearWise, fetchJudgementListSuccess, setJudgementData, setJudgementYear, setJudgementLoad } = props;
    const classes = useStyles();

    const [data, setData, refData] = useState(judgementList);
    const [offset, setOffset, refOffset] = useState(judgementList.length === 0 ? 0 : judgementList.length - 20);
    const [load, setLoad, refLoad] = useState(judgementLoad);

    // console.log('judgementListLoad',judgementListLoad);
    // console.log('refLoad',refLoad.current);

    // let date = new String(new Date().toISOString()).substring(0,10);

    const [fromDate, setFromDate] = React.useState(props.judgementFrom);
    const [toDate, setToDate] = React.useState(props.judgementTo);
    const [totalCount, setTotalCount] = useState(0);

    // console.log(props.judgementFrom,props.judgementTo)

    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        props.setMenuItems([], 2);
        // firebaseAnalytics.logEvent(`JudgementListPage_visited`);
    }, [props])

    // console.log("PROPS INSIDE JUDGEMENT===>", props)

    let yyear = props.location.state ? props.location.state.year : 2021;

    // React.useEffect(() => {
    //     props.setMenuItems([], 2);

    // }, [])

    useEffect(() => {

        if (yyear !== judgementListYear) {

            fetchJudgementListSuccess([], 0, true);
            setData([]);
            setOffset(0);
            setLoad(true);
            setJudgementYear(yyear);
        }

    }, [yyear])


    // useEffect(() => {
    //     if (refData.current.length === 0) {
    //         setSpinner(true);
    //         props.fetchRecentJudgements()
    //             .then((res) => {
    //                 setSpinner(false);
    //                 if (refLoad.current === true) {
    //                     let arr = res.data.results;
    //                     arr.sort((a, b) => {
    //                         return b.id - a.id;
    //                     })
    //                     if (res.data.next === null) {
    //                         setLoad(false);
    //                         setJudgementLoad(false)
    //                     }
    //                     let recentData = [], i;

    //                     for (i = 0; i < 20; i++) {
    //                         recentData.push(arr[i]);
    //                     }

    //                     setData([...refData.current, ...recentData])
    //                     setJudgementData(refData.current);
    //                 }

    //             })
    //             .catch((err) => {
    //                 setSpinner(false);
    //                 console.log(err);
    //             });
    //     }
    // }, [])




    const getJudgementData = () => {
        console.log('insidebtn')
        setSpinner(true);
        fetchJudgementListSuccess([], 0, true);
        setData([]);
        setOffset(0);
        setLoad(true);

        fetchJudgementList(fromDate, toDate, 0)
            .then((res) => {
                setSpinner(false);
                if (refLoad.current === true) {
                    let arr = res.data.results;
                    arr.sort((a, b) => {
                        return a.id - b.id;
                    })
                    if (res.data.next === null) {
                        setLoad(false);
                        setJudgementLoad(false)
                    }
                    setData([...refData.current, ...arr])
                    setJudgementData(refData.current);
                }

            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    logoutUser()
                    history.push("/")
                }
            });
    }

    useEffect(() => {

        // console.log('inside useeffect data fetch');
        if (refData.current.length === 0) {
            // console.log('inside useeffect data fetch inside if');
            fetchJudgementYearWise(refOffset?.current, yyear)
                .then((res) => {

                    if (refLoad.current === true) {
                        //  console.log('inside true');
                        setTotalCount(res.data.count)
                        // console.log("res===>", res)
                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        if (res.data.next === null) {
                            setLoad(false);
                            setJudgementLoad(false)
                        }

                        setData([...refData.current, ...arr])
                        setJudgementData(refData.current);

                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        }
    }, [yyear])

    // const getNextData = (offset) => {
    //     setOffset(offset)
    //     fetchJudgementList(fromDate, toDate, offset)
    //         .then((res) => {
    //             if (refLoad.current === true) {
    //                 let arr = res.data.results;
    //                 arr.sort((a, b) => {
    //                     return a.id - b.id;
    //                 })
    //                 if (res.data.next === null) {
    //                     setLoad(false);
    //                     setJudgementLoad(false)
    //                 }
    //                 setData([...refData.current, ...arr])
    //                 setJudgementData(refData.current);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });

    // }


    const getNextData = (offset) => {
        setOffset(offset)
        fetchJudgementYearWise(refOffset.current, yyear)
            .then((res) => {
                if (load === true) {
                    let arr = res.data.results;
                    arr.sort((a, b) => {
                        return a.id - b.id;
                    })
                    if (res.data.next === null) {
                        setLoad(false); setJudgementLoad(false)
                    }
                    setData([...refData.current, ...arr])
                    setJudgementData(refData.current);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    logoutUser()
                    history.push("/")
                }
            });

    }
    return (

        <>

            <MainContentSection>
                <div className={classes.container}>
                    <h2 className={classes.heading}>
                        Judgements for {yyear}{" "}({totalCount})
                    </h2>
                    <hr className={classes.divider} />
                    <div className={classes.content}>
                        <Grid>
                            <TextField
                                component={Paper}
                                className="mr-3 mt-3"
                                variant="outlined"
                                label="From Date"
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputProps: { min: "" },
                                }}
                            />
                            <TextField
                                component={Paper}
                                className="mt-3"
                                variant="outlined"
                                label="To Date"
                                type="date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputProps: { min: fromDate },
                                }}
                            />
                        </Grid>
                        <Button
                            className='mt-3'
                            onClick={() => getJudgementData()}
                            variant="contained"
                            color="primary">
                            Get Judgements
                        </Button>
                        <br /><br /><br />
                        {
                            refData.current.length === 0 ?
                                spinner ?
                                <div className={classes.spinnerContainer}>
                                    <CircularProgress className={classes.spinner} />
                                </div> :
                                <div style={{ display: 'flex', justifyContent: 'center' }}><p className={classes.noDataDiv}>No data Found</p></div> :
                                <InfiniteScroll
                                    dataLength={refData.current.length}
                                    next={() => {
                                        getNextData(refOffset.current + 20);
                                    }}
                                    hasMore={load}
                                    loader={<div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>}
                                    style={{ overflow: 'hidden' }}>

                                    <List>
                                        {
                                            refData.current.map((info, index) => {
                                                // console.log('info==>',info)
                                                return (
                                                    <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                        // getNextData(refData.current);
                                                        // props.setJudgementFrom(fromDate)
                                                        // props.setJudgementTo(toDate)
                                                        setJudgementData(refData.current)
                                                        history.push(`/resource/supremeCourtJudgements/${info.id}`)
                                                    }}>
                                                        <h6><b>{parse(info.title)}</b></h6>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </InfiniteScroll>
                        }

                    </div>
                </div>
            </MainContentSection>
        </>
    )
}

// export default Amendments
const mapStateToProps = (state) => {

    let {
        fetchJudgementList: { data, offset, loadMore, from, to, language, year }
    } = state;

    return {
        judgementList: data,
        judgementOffset: offset,
        judgementLoad: loadMore,
        judgementFrom: from,
        judgementTo: to,
        judgementLanguage: language,
        judgementYear: year,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchJudgementYearWise: (offset, year) => dispatch(fetchJudgementYearWise(offset, year)),
        fetchJudgementList: (from, to, offset) => dispatch(fetchJudgementList(from, to, offset)),
        fetchJudgementListSuccess: (list, offset, load) => dispatch(fetchJudgementListSuccess(list, offset, load)),
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
        setJudgementLanguage: (data) => dispatch(setJudgementLanguage(data)),
        setJudgementData: (data) => dispatch(setJudgementData(data)),
        setJudgementLoad: (data) => dispatch(setJudgementLoad(data)),
        setJudgementOffset: (data) => dispatch(setJudgementOffset(data)),//setJudgementFrom
        setJudgementFrom: (data) => dispatch(setJudgementFrom(data)),
        setJudgementTo: (data) => dispatch(setJudgementTo(data)),
        fetchRecentJudgements: () => dispatch(fetchRecentJudgements()),
        setJudgementYear: (data) => dispatch(setJudgementYear(data))
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Judgements)










