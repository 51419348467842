import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchConstitutionData } from './Redux/ConstitutionMenuItems/ConstitutionMenuItemsActions'
import { fetchConstitutionSuccess } from './Redux/ConstitutionMenuItems/ConstitutionMenuItemsActions'
import Amendments from './Pages/Amendment/Amendments';
import IndividualAmendment from './Pages/Amendment/IndividualAmendment';
import Schedules from './Pages/Schedule/Schedules';
import IndividualSchedule from './Pages/Schedule/IndividualSchedule';
import Preamble from './Pages/Preamble';
import Articles from './Pages/Article/Articles';
import IndividualArticle from './Pages/Article/IndividualArticle';
import ConstituentAssemblyDebates from './Pages/ConstituentAssemblyDebates/ConstituentAssemblyDebates';
import Homepage from './Pages/HomePage/Homepage';
import ResourcePage from './Pages/ResoucePage/ResourcePage';
import Header from './components/Header/Header';
import Login from './components/Login/Login'
import SignUp from './components/Login/SignUp'
import LeftSidebar from './components/SidebarSection/LeftSidebar'
import IndividualConstituentAssemblyDebate from './Pages/ConstituentAssemblyDebates/IndividualConstituentAssemblyDebate'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import SearchResult from './components/SearchResult/SearchResult'
import Judgements from './Pages/SupremeCourtJudgements/Judgements'
import IndividualJudgement from './Pages/SupremeCourtJudgements/IndividualJudgement'
import Laws from './Pages/Law/Laws'
import IndividualLaw from './Pages/Law/IndividualLaw'
import PreambleInfo from './Pages/Law/PreambleInfo'
import ScheduleInfo from './Pages/Law/ScheduleInfo'
import SectionInfo from './Pages/Law/SectionInfo'
import SectionList from './Pages/Law/SectionList'
import ChapterList from './Pages/Law/ChapterList'
import LawLink from './Pages/Law/LawLink'
import UserNoteLink from './Pages/Notes/UserNoteLink'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
// import { getStaffStatus } from '../config/cookiesInfo';
import { getStaffStatus } from './config/cookiesInfo'

//Knowledge Graph

import KnowledgeGraph from './Pages/knowledgegraphpages/KnowledgeGraph'

//Admin
import AdminDashboardKnowledgeGraphList from './components/AdminDashboard/pages/AdminDashboardKnowledgeGraphList'
import ReviewKnowledgeGraph from './components/AdminDashboard/pages/ReviewKnowledgeGraph'
import DeleteAccount from './Pages/DeleteAccount/DeleteAccount'
import UserProfile from './Pages/Profile/UserProfile'
import UserNotes from './Pages/Notes/UserNotes'

function App(props) {
  // console.log('App',props)
  // console.log('getStaffStatus',getStaffStatus())

  useEffect(() => {

    props.fetchConstitutionSuccess([]);
    props.fetchConstitutionMenu();

  }, [props.currentLanguageType, getStaffStatus()])


  return (
    <div className="App">

      <Router>
        <Header />
        <Switch>

          <Route path='/' exact component={Homepage} />
          <Route path='/login' exact component={Login} />
          <Route path='/signup' exact component={SignUp} />
          <Route path='/privacy-policy' exact component={PrivacyPolicy} />
          <Route path='/delete-account' exact component={DeleteAccount} />
          <Route path='/user-profile' exact component={UserProfile} />
          <Route path='/user-notes' exact component={UserNotes} />
          <Route path='/user-note' exact component={UserNoteLink} />


          {/* added below route to redirect to preamble when site loads (since /coi-webapp route is specified in package.json)*/}
          <Route path='/coi-webapp' exact component={Preamble} />
          <Route path='/searchResult/:text/:category' exact component={SearchResult} />
          {/* <Route path='/searchResult' exact component={SearchResult}/> */}
          {/* <Route path='/constitution' exact component={ConstitutionPage}/> */}
          <Route path='/constitution/preamble' exact component={Preamble} />
          <Route path='/resource' exact component={ResourcePage} />


          <Route path='/constitution/amendments' exact component={Amendments} />
          <Route path='/constitution/amendments/:id' exact component={IndividualAmendment} />


          <Route path='/constitution/schedules' exact component={Schedules} />
          <Route path='/constitution/schedules/:id' exact component={IndividualSchedule} />
          {/* <Route path='/constitution/schedules/individualSchedule' exact component={IndividualSchedule}/> */}


          {/* <Route path='/constitution/parts/chapters/articles' exact component={Articles}/> */}
          <Route path='/constitution/parts/chapters/articles/:partId/:chapterId' exact component={Articles} />
          <Route path='/constitution/parts/chapters/articles/:id' exact component={IndividualArticle} />
          {/* <Route path='/constitution/amendments/individualAmendment' exact component={IndividualAmendment}/> */}
          {/* <Route path='/constitution/parts/chapters/articles/individualArticle' exact component={IndividualArticle}/> */}


          {/* <Route path='/resource/constituentAssemblyDebates' exact component={ConstituentAssemblyDebates}/> 
                 <Route path='/resource/constituentAssemblyDebates/individualDebate' exact component={IndividualConstituentAssemblyDebate}/> */}
          <ProtectedRoute path='/resource/constituentAssemblyDebates' exact component={ConstituentAssemblyDebates} />
          <ProtectedRoute path='/resource/constituentAssemblyDebates/:id' exact component={IndividualConstituentAssemblyDebate} />
          {/* <ProtectedRoute path='/resource/constituentAssemblyDebates/:id' exact component={IndividualConstituentAssemblyDebate}/> */}


          <ProtectedRoute path='/resource/supremeCourtJudgements' exact component={Judgements} />
          <ProtectedRoute path='/resource/supremeCourtJudgements/:id' exact component={IndividualJudgement} />


          <Route path='/law' exact component={LawLink} />
          <ProtectedRoute path='/laws' exact component={Laws} />
          {/* <Route path='/laws' exact component={Laws}/> */}
          <ProtectedRoute path='/laws/individualLaw' exact component={IndividualLaw} />
          {/* <ProtectedRoute path='/laws/individualLaw/preambleInfo' exact component={PreambleInfo}/> */}
          <ProtectedRoute path='/laws/individualLaw/preamble/:id' exact component={PreambleInfo} />
          <ProtectedRoute path='/laws/individualLaw/schedule/:id' exact component={ScheduleInfo} />
          {/* <ProtectedRoute path='/laws/individualLaw/scheduleInfo' exact component={ScheduleInfo}/> */}
          {/* <ProtectedRoute path='/laws/individualLaw/sectionInfo' exact component={SectionInfo}/> */}
          <ProtectedRoute path='/laws/individualLaw/section/:id' exact component={SectionInfo} />
          <ProtectedRoute path='/laws/individualLaw/sectionList' exact component={SectionList} />
          <ProtectedRoute path='/laws/individualLaw/chapterList' exact component={ChapterList} />


          {/* Knowledge Graph  */}
          <ProtectedRoute path='/constitution/knowledgegraph/:pathname/:id' exact component={KnowledgeGraph} />
          <ProtectedRoute path='/constitution/knowledgegraph/laws/:pathname/:id' exact component={KnowledgeGraph} />

          {/* admin DashBoard */}

          <ProtectedRoute path='/admin/dashboard' exact component={AdminDashboardKnowledgeGraphList} />
          <ProtectedRoute path='/admin/ReviewKnowledgeGraph' exact component={ReviewKnowledgeGraph} />



        </Switch>
        {props.displayLeftSidebarval ? <LeftSidebar /> : null}
      </Router>


    </div>);
}




const mapStateToProps = (state) => {
  return {
    currentLanguageType: state.toggleLanguage.currentLanguageType,
    displayLeftSidebarval: state.displayLeftSidebar.displayLeftSidebar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // toggleLanguage : ()=>dispatch(toggleLanguage()),
    fetchConstitutionMenu: () => dispatch(fetchConstitutionData()),
    fetchConstitutionSuccess: (data) => dispatch(fetchConstitutionSuccess(data)),
    // displayLeftSidebar : (value) => dispatch(displayLeftSidebar(value)),
  }
}



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
