import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'

import useState from 'react-usestateref'
import FullScreenContent from '../../components/MainContent/FullScreenContent';
import { fetchChaptersUnderPart, fetchSectionsUnderPart } from '../../Redux/APIs/FetchDetails/FetchChaptersUnderPart'
import { logoutUser } from '../../config/firebaseAuthentication';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {
        width: '80%',
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            marginTop: -10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }
    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        color: 'blue',
    },
    spinnerContainer: {
        display: "flex"
    },

    itemStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            marginBottom: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 17.5,
            marginBottom: 5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginBottom: 6,
        },
    }

}));


const ChapterList = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [chapters, setChapters, refChapters] = useState([]);
    const [section, setSections, refSections] = useState([]);
    const [nextURL, setNextURL] = useState(null)

    let partId = props.location.state ? props.location.state.partId : '';
    let lawId = props.location.state ? props.location.state.lawId : '';


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchChaptersUnderPart(lawId, partId)
            .then((res) => {
                let arr = res.data.results;
                arr.sort((a, b) => {
                    return a.id - b.id;
                })
                setChapters(arr)
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    logoutUser()
                    history.push("/")
                }
            });
    }, [])


    return (

        <>
            {
                !props.location.state ?
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h3 className={classes.heading} style={{ paddingTop: 50 }}>No data found</h3>
                        </div>
                    </FullScreenContent> :
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>Chapters</h2>
                            <hr className={classes.divider} />
                            <div className={classes.content}>
                                {

                                    <List>
                                        {
                                            refChapters.current.map((info, index) => {
                                                // console.log('lawChapter_info',info)
                                                return (
                                                    <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                        history.push('/laws/individualLaw/sectionList',
                                                            {
                                                                chapterId: info.id,
                                                                partId: partId,
                                                                lawId: info.law
                                                            })
                                                    }}>
                                                        <h5 className={classes.itemStyle}><b>{info.title} {info.description && info.description.length > 0 ? `(${info.description})` : ``}</b></h5>
                                                        {/* <h5><b>{info.title} ({info.description})</b></h5> */}
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                }
                            </div>

                        </div>

                    </FullScreenContent>
            }
        </>
    )
}

export default ChapterList







