
import axios from 'axios'
import { HOST } from '../../../config/config'
import { getLoginStatus, getToken } from '../../../config/cookiesInfo'

let loginStatus = getLoginStatus()

export const getHighlightText = (module_id, module_type) => {

    if (loginStatus) {
        return new Promise((resolve, reject) => {
            axios.get(`${HOST}highlight_text/?module_id=${module_id}&module_type=${module_type}`, {
                headers: {
                    "Authorization": `token ${getToken()}`
                },
            })
                .then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err)
                });
        })
    }
}

export const postHighlightText = (payload) => {
    return new Promise((resolve, reject) => {
        axios(`${HOST}highlight_text/`, {
            method: 'post',
            headers: {
                "Authorization": `Token ${getToken()}`
            },
            data: payload
        })
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
    })
};

export const updateHighlightText = (id, payload) => {
    return new Promise((resolve, reject) => {
        axios(`${HOST}highlight_text/${id}/`, {
            method: 'put',
            headers: {
                "Authorization": `token ${getToken()}`
            },
            data: payload
        })
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
    })
}

export const fetchAllNotesAndHighlights = (offset, searchText = "") => {
    return new Promise((resolve, reject) => {
        axios.get(`${HOST}highlight_text/?limit=20&offset=${offset}&ordering=-created_at&note__icontains=${searchText}`,
            {
                method: 'get',
                headers: {
                    "Authorization": `token ${getToken()}`
                }
            })
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            });
    })
}