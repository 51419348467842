export const createHighlightedElement = (color, handlePopoverOpen, handlePopoverClose) => {
    const highlightedElement = document.createElement('span');
    highlightedElement.style.backgroundColor = color;
    highlightedElement.style.cursor = color === 'white' ? 'auto' : 'pointer';
    highlightedElement.style.position = 'relative';

    // Add event listener for click to open popover
    highlightedElement.addEventListener('click', handlePopoverOpen);

    // Add event listener for mouseenter to open popover
    highlightedElement.addEventListener('click', handlePopoverOpen);

    // Add event listener for mouseleave to close popover
    // highlightedElement.addEventListener('mouseleave', handlePopoverClose);

    return highlightedElement;
};

export const onHighlightSelection = (selection, color, handlePopoverOpen, handlePopoverClose, elementId, option) => {
    const range = selection.getRangeAt(0);
    const highlightedElement = createHighlightedElement(color, handlePopoverOpen, handlePopoverClose);
    const selectedText = selection.toString();

    if (!selectedText || range.collapsed) return; // Check if there is selected text and if the range is not collapsed

    // Check if the selected range contains any non-text nodes
    const containsNonTextNodes = Array.from(range.cloneContents().childNodes).some(node => node.nodeType !== Node.TEXT_NODE);


    // Ensure only one text node wraps the selection
    if (containsNonTextNodes) {
        const fragment = range.extractContents();
        const textNode = document.createTextNode(fragment.textContent);
        range.insertNode(textNode);
        range.selectNode(textNode);
    }

    const elements = document.getElementsByClassName(option.startindex +"-" + option.endindex);
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }

    
    range.surroundContents(highlightedElement);
    removeUnWantedSpan(elementId);

    if (option.note && color !== "white") {
        const childSpan = document.createElement('span');
        childSpan.className = option.startindex + "-" + option.endindex;
        childSpan.style = "position: absolute; top: -7px; left: -6px; width: 9px; height: 9px; background-color: #17d2cc; border-radius: 50%;";
        highlightedElement.appendChild(childSpan);
    }

};


export const getSelectionFromOffsets = (startOffset, endOffset, node) => {
    // console.log("node",node)
    const selection = window.getSelection();
    const range = document.createRange();

    let foundStart = false;
    let currentOffset = 0;

    function traverse(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            // if node type is text store it's length
            const len = node.textContent.length;

            // Check if the start offset is within the current text node
            if (!foundStart && startOffset <= currentOffset + len) {
                range.setStart(node, startOffset - currentOffset);
                foundStart = true;
            }

            // Check if the end offset is within the current text node
            if (foundStart && endOffset <= currentOffset + len) {
                range.setEnd(node, endOffset - currentOffset);
                return true;
            }

            // Increment the current offset by the length of the text node
            currentOffset += len;
        } else {
            // Traverse child nodes recursively
            for (let i = 0; i < node.childNodes.length; i++) {
                if (traverse(node.childNodes[i])) return true;
            }
        }
        return false;
    }

    // Start traversing from the given node
    traverse(node);

    // Add the range to the selection
    selection.removeAllRanges();
    selection.addRange(range);
};

export const getOffsetsFromSelection = (element) => {
    // console.log("selected element<>",element)
    if (!element) return;
    const html = element.innerHTML; // store original HTML
    element.querySelectorAll('.ignore').forEach((e) => e.remove()); // remove ignore elements

    let start = 0, end = 0;
    let sel, range, priorRange, text;
    if (typeof window.getSelection != "undefined") {
        sel = window.getSelection();
        text = sel + '';
        if (window.getSelection().rangeCount <= 0) {
            return;
        }
        range = window.getSelection().getRangeAt(0);
        priorRange = range.cloneRange();
        priorRange.selectNodeContents(element);
        priorRange.setEnd(range.startContainer, range.startOffset);
        start = priorRange.toString().length;
        end = start + (sel + '').length;
    } else if (typeof document.selection !== "undefined" &&
        (sel = document.selection).type !== "Control") {
        text = sel + '';
        range = sel.createRange();
        priorRange = document.body.createTextRange();
        priorRange.moveToElementText(element);
        priorRange.setEndPoint("EndToStart", range);
        start = priorRange.text.length;
        end = start + (sel + '').length;
    }
    element.innerHTML = html;  // restore HTML
    sel.removeAllRanges();
    console.log('===>start', start)
    console.log('===>end', end)
    console.log('===>text', text)
    return { start, end, text };
}

// export const getOffsetsFromSelection = (element) => {
//     if (!element) return;
//     const html = element.innerHTML; // store original HTML
//     element.querySelectorAll('.ignore').forEach((e) => e.remove()); // remove ignore elements

//     let start = 0, end = 0;
//     let sel, range, priorRange, text;
//     if (typeof window.getSelection != "undefined") {
//         sel = window.getSelection();
//         text = sel.toString(); // Changed to use toString() to get the selected text
//         if (!text) return; // Return if no text is selected
//         range = sel.getRangeAt(0);
//         priorRange = range.cloneRange();
//         priorRange.selectNodeContents(element);
//         priorRange.setEnd(range.startContainer, range.startOffset);
//         start = priorRange.toString().length;
//         end = start + text.length;
//         // Perform additional action here when text is selected
//         console.log('Selected text:', text);
//     } else if (typeof document.selection !== "undefined" &&
//         (sel = document.selection).type !== "Control") {
//         text = sel.toString();
//         if (!text) return;
//         range = sel.createRange();
//         priorRange = document.body.createTextRange();
//         priorRange.moveToElementText(element);
//         priorRange.setEndPoint("EndToStart", range);
//         start = priorRange.text.length;
//         end = start + text.length;
//         console.log('Selected text:', text);
//     }
//     element.innerHTML = html;  // restore HTML
//     sel.removeAllRanges();
//     console.log('===>start',start);
//     console.log('===>end',end);
//     console.log('===>text',text);
//     return { start, end, text };
// }

export const removeUnWantedSpan = (elementId) => {
    const textNode = document.getElementById(elementId);
    const spans = textNode?.querySelectorAll('span');
    spans?.forEach(span => {
        if (span.textContent.trim() === '') {
            const parent = span.parentNode;
            if (parent) {
                parent.removeChild(span);
            }
        }
    });
};


function getOffsetsWithinElement(range, element) {
    var preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.startContainer, range.startOffset);
    var startOffset = preCaretRange.toString().length;
    var endOffset = startOffset + range.toString().length;
    return { startOffset: startOffset, endOffset: endOffset };
}